import { Api } from "@/models/class/api.class";
import { ResponseListWarehouseLocation } from "@/models/interface-v2/warehouse.interface";
import { ErrorResponseModel, RequestQueryParamsModel } from "@/models/interface/http.interface";
import { HttpClient } from "@/services/http.service";
import { AxiosError } from "axios";

export class WarehouseLocationService extends HttpClient {
  constructor() {
    super()
  }

  /**
   * get all warehouse location
   * @param params query params
   * @returns list of warehouse location
   */
  listWarehouseLocation(params: RequestQueryParamsModel): Promise<ResponseListWarehouseLocation> {
    return this.get<ResponseListWarehouseLocation>(Api.WarehouseLocation, { params })
    .then(this.success)
    .catch((error: AxiosError<ErrorResponseModel>) => {
      throw this.error(error)
    })
  }
}

export const warehouseLocationService = new WarehouseLocationService()
