import Store from '@/store'
import jwtDecode from 'jwt-decode'
import { DecodedToken } from '@/models/interface/common.interface'
import { IAuthorities } from '@/models/interface/authorities.interface';

export function isAccessTokenExpire(): boolean {
  const refresh_token = Store.state.refresh_token
  if (refresh_token) {
    const decoded: DecodedToken = jwtDecode(refresh_token)
    const { exp } = decoded
    return exp < Date.now() / 1000
  }
  return false
}

export function getauthorities(): IAuthorities[] {
  const access_token = Store.state.access_token
  const tempData = [] as IAuthorities[]
  if (access_token) {
    const decoded: DecodedToken = jwtDecode(access_token)
    decoded.authorities.forEach(item => {
      const obj = {
        name: item.split(':')[0],
        value: [item.split(':')[1]],
      }
        if (tempData.length < 1) {
          tempData.push(obj)
        } else {
          if (tempData.findIndex(data => data.name === obj.name) === -1) {
            tempData.push(obj)
          } else {
            tempData[tempData.findIndex(data => data.name === obj.name)].value.push(obj.value[0])
          }
        }
    })
    tempData?.forEach(item => {
      const tempRoutes = [] as any[]
      const tempActions = [] as any[]
      for (const data of item.value) {
        const routesPush = '/'+item.name+'/'+data
        tempRoutes.push(routesPush)  
      }
      item.routes = tempRoutes
      for (const data2 of item.value) {
          if (data2 !== 'create' && data2 !== 'read'){
            tempActions.push(data2)
          }
      }
      item.actions = tempActions
    })
    return tempData;
  }
  return tempData
}
