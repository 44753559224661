import { Api } from "@/models/class/api.class";
import { RequestWarehouseTransferCreate, ResponseListWarehouseTransfer, ResponseWarehouseTransfer } from "@/models/interface-v2/warehouse-transfer.interface";
import { ErrorResponseModel, RequestQueryParamsModel } from "@/models/interface/http.interface";
import { HttpClient } from "@/services/http.service";
import { AxiosError } from "axios";

export class WarehouseTransferService extends HttpClient {
  public constructor() {
    super()

    this.getDownloadWarehouseTransfer = this.getDownloadWarehouseTransfer.bind(this)
    this.getPrintWarehouseTransfer = this.getPrintWarehouseTransfer.bind(this)
  }

  getDownloadWarehouseTransfer(params: RequestQueryParamsModel): Promise<ArrayBuffer> {
    return this.get<ArrayBuffer>(Api.WarehouseTransfer + `/report/download` , { params, responseType: 'arraybuffer' })
    .then(this.success)
    .catch((error: AxiosError<ErrorResponseModel>) => {
      throw this.error(error)
    })
  }

  getPrintWarehouseTransfer(params: RequestQueryParamsModel): Promise<ArrayBuffer> {
    return this.get<ArrayBuffer>(Api.WarehouseTransfer + `/report/print` , { params, responseType: 'arraybuffer' })
    .then(this.success)
    .catch((error: AxiosError<ErrorResponseModel>) => {
      throw this.error(error)
    })
  }

  /**
   * create a new warehouse transfer
   * @param payload request warehouse transfer
   * @returns Promise
   */
   public createWarehouseTransfer(payload: RequestWarehouseTransferCreate): Promise<ResponseWarehouseTransfer> {
    return this.post<ResponseWarehouseTransfer, RequestWarehouseTransferCreate>(Api.WarehouseTransfer, payload)
    .then(this.success)
    .catch((error: AxiosError<ErrorResponseModel>) => {
      throw this.error(error);
    });
  }

  /**
   * update warehouse transfer
   * @param id warehouse transfer id
   * @param payload request warehouse transfer
   * @returns Promise
   */
   public updateWarehouseTransfer(id: string, payload: RequestWarehouseTransferCreate): Promise<ResponseWarehouseTransfer> {
    return this.put<ResponseWarehouseTransfer, RequestWarehouseTransferCreate>(Api.WarehouseTransfer + `/${id}`, payload)
    .then(this.success)
    .catch((error: AxiosError<ErrorResponseModel>) => {
      throw this.error(error);
    });
  }

  /**
   * get detail warehouse transfer
   * @param id warehouse transfer id
   * @returns Promise
   */
   public detailWarehouseTransfer(id: string): Promise<ResponseWarehouseTransfer> {
    return this.get<ResponseWarehouseTransfer>(Api.WarehouseTransfer + `/${id}`)
    .then(this.success)
    .catch((error: AxiosError<ErrorResponseModel>) => {
      throw this.error(error);
    });
  }

  /**
   * submit warehouse transfer
   * @param id warehouse transfer id
   * @returns Promise
   */
   public submitWarehouseTransfer(id: string): Promise<ResponseWarehouseTransfer> {
    return this.put<ResponseWarehouseTransfer, boolean>(Api.WarehouseTransferSubmit + `/${id}`)
    .then(this.success)
    .catch((error: AxiosError<ErrorResponseModel>) => {
      throw this.error(error);
    });
  }

  /**
   * receive warehouse transfer
   * @param id warehouse transfer id
   * @returns Promise
   */
   public receiveWarehouseTransfer(id: string, payload: RequestWarehouseTransferCreate): Promise<ResponseWarehouseTransfer> {
    return this.put<ResponseWarehouseTransfer, RequestWarehouseTransferCreate>(Api.WarehouseTransferReceive + `/${id}`, payload)
    .then(this.success)
    .catch((error: AxiosError<ErrorResponseModel>) => {
      throw this.error(error);
    });
  }

  /**
   * cancel warehouse transfer
   * @param id warehouse transfer id
   * @returns Promise
   */
   public cancelWarehouseTransfer(id: string): Promise<boolean> {
    return this.put<boolean, string>(Api.WarehouseTransferCancel + `/${id}`)
    .then(this.success)
    .catch((error: AxiosError<ErrorResponseModel>) => {
      throw this.error(error);
    });
  }

  /**
   * get all warehouse transfer list
   * @param params query params
   * @returns Promise
   */
   public listWarehouseTransfer(params: RequestQueryParamsModel): Promise<ResponseListWarehouseTransfer> {
    return this.get<ResponseListWarehouseTransfer>(Api.WarehouseTransfer, { params })
    .then(this.success)
    .catch((error: AxiosError<ErrorResponseModel>) => {
      throw this.error(error);
    });
  }
}

export const warehouseTransferService = new WarehouseTransferService()
