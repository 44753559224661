












































































































































































































import Vue from "vue";
import printJS from "print-js";
import moment from "moment";

import { RequestQueryParamsModel } from "@/models/interface/http.interface";
import {
  OptionModel,
  ResponsePagination,
} from "@/models/interface/common.interface";
import { ResponseListWarehouseTransfer } from "@/models/interface-v2/warehouse-transfer.interface";
import { ResponseWarehouseLocation } from "@/models/interface-v2/warehouse.interface";

import { warehouseTransferService } from "@/services-v2/warehouse-transfer.service";
import { warehouseLocationService } from "@/services-v2/warehouse-location.service";

import { WAREHOUSE_STATE } from "@/models/enums/warehouse-transfer.enum";
import { DEFAULT_DATE_FORMAT } from "@/models/constant/date.constant";
import { debounceProcess } from "@/helpers/debounce";
import { mapGetters } from "vuex";
import { getauthorities } from "@/helpers/cookie";

export default Vue.extend({
  name: "WarehouseTransferList",
  data() {
    this.getListLocation = debounceProcess(this.getListLocation, 400);
    this.getList = debounceProcess(this.getList, 400);
    this.findListDocument = debounceProcess(this.findListDocument, 400);
    return {
      DEFAULT_DATE_FORMAT,
      dataList: {
        currentPage: 0,
        data: [],
        totalElements: 0,
        totalPages: 0,
      } as ResponseListWarehouseTransfer,
      columnsTable: [
        {
          title: this.$t("lbl_document_number"),
          dataIndex: "documentNumber",
          key: "documentNumber",
          sorter: false,
          width: "",
          scopedSlots: { customerRender: "isNull" },
        },
        {
          title: this.$t("lbl_source"),
          dataIndex: "sourceName",
          key: "sourceName",
          sorter: false,
          width: "",
          scopedSlots: { customerRender: "isNull" },
        },
        {
          title: this.$t("lbl_destination"),
          dataIndex: "destinationName",
          key: "destinationName",
          sorter: false,
          width: "",
          scopedSlots: { customerRender: "isNull" },
        },
        {
          title: this.$t("lbl_transfer_date"),
          dataIndex: "date",
          key: "date",
          sorter: false,
          width: "",
          scopedSlots: { customerRender: "isNull" },
        },
        {
          title: this.$t("lbl_status"),
          dataIndex: "state",
          key: "state",
          sorter: false,
          width: "",
          scopedSlots: { customerRender: "isNull" },
        },
      ] as any,
      limitPage: 10 as number,
      page: 1 as number,
      search: "" as string,
      sortBy: "date:desc" as string,
      filterList: {
        docNumber: "",
        status: undefined,
        source: "",
        destination: "",
        date: [],
      },
      labelCol: { span: 7 },
      wrapperCol: { span: 10 },
      form: {
        rules: {
          docNumber: {
            label: "lbl_document_number",
          },
          status: {
            label: "lbl_status",
          },
          source: {
            label: "lbl_source",
          },
          destination: {
            label: "lbl_destination",
          },
          date: {
            label: "lbl_date",
          },
        },
        model: {
          docNumber: "" as string,
          status: "" as string,
          source: "" as string,
          destination: "" as string,
          date: [] as any,
        },
      },
      listWarehouseState: [
        {
          label: "Cancelled",
          value: WAREHOUSE_STATE.CANCELLED,
        },
        {
          label: "Draft",
          value: WAREHOUSE_STATE.DRAFT,
        },
        {
          label: "Received",
          value: WAREHOUSE_STATE.RECEIVED,
        },
        {
          label: "Submitted",
          value: WAREHOUSE_STATE.SUBMITTED,
        },
      ] as OptionModel[],
      dataListLocation: [] as ResponseWarehouseLocation[],
      listDocumentNumber: {
        currentPage: 0,
        data: [],
        totalElements: 0,
        totalPages: 0,
      } as ResponseListWarehouseTransfer,
      loading: {
        docNumber: false as boolean,
        source: false as boolean,
        destination: false as boolean,
        loadingTable: false as boolean,
        loadingDownload: false as boolean,
        loadingPrint: false as boolean,
      },
      authPrivilege: [] as string[],
    };
  },
  computed: {
    ...mapGetters({
      getUserPrivileges: "authStore/GET_USER_PRIVILEGES",
    }),
    hasPrivilegeCreate(): boolean {
      return this.getUserPrivileges.find(x => x.key === "warehouse-transfer" && x.privilege.update && x.privilege.create);
    },
  },
  created() {
    this.setAuthorities();
  },
  mounted() {
    this.getListLocation("");
    this.findListDocument("");
    this.clearFilter();
    this.findData();
  },
  methods: {
    moment,
    setAuthorities(): void {
      const auth = getauthorities();
      auth.forEach(dataAuth => {
        if(dataAuth.actions?.length && dataAuth?.name === "warehouse-transfer") {
          this.authPrivilege = dataAuth.value;
          this.columnsTable.push(
            {
              title: this.$t("lbl_action"),
              dataIndex: "operation",
              key: "operation",
              scopedSlots: { customRender: "operation" },
              button: dataAuth.actions,
              width: "120px",
              align: "center",
              fixed: "right",
            },
          );
        }
      });
    },
    handleDownload() {
      const params: RequestQueryParamsModel = {
        page: this.page - 1,
        limit: this.limit,
      };
      this.loading.loadingDownload = true;
      warehouseTransferService
        .getDownloadWarehouseTransfer(params)
        .then((response) => {
          if (response) {
            const url = window.URL.createObjectURL(new Blob([response]));
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "warehouse_transfer.xlsx"); //or any other extension
            document.body.appendChild(link);
            link.click();
          }
        })
        .finally(() => (this.loading.loadingDownload = false));
    },
    handlePrint() {
      const params: RequestQueryParamsModel = {
        page: 0,
        limit: this.dataList.totalElements,
      };
      if (this.search) params.search = this.search;
      this.loading.loadingPrint = true;
      warehouseTransferService.listWarehouseTransfer(params)
      .then((res) => {
        res.data.forEach((x, idx) => {
          x["key"] = idx;
          x["sourceName"] = x.sourceLocation || "-";
          x["destinationName"] = x.destinationLocation || "-";
          x.date = this.moment(x.date).format(DEFAULT_DATE_FORMAT);
        });
        const properties = [
          "documentNumber",
          "sourceLocation",
          "destinationLocation",
          "date",
          "state",
        ];
        printJS({
          printable: res.data,
          properties,
          type: "json",
          gridHeaderStyle: "border: 1px solid #000",
          gridStyle: "text-align: center;border: 1px solid #000",
          onError: (error) => {
            this.$notification.error({
              message: this.$t("lbl_error_title").toString(),
              description: error.message
            });
          }
        });
      })
      .finally(() => (this.loading.loadingPrint = false));
    },
    responseEditTable(row: any): void {
      this.$router.push(`./warehousetransfer?id=${row.data.id}`);
    },
    createNew(): void {
      this.$router.push("./warehousetransfer");
    },
    findListDocument(valueSearch: string): void {
      const params: RequestQueryParamsModel = {
        page: 0,
        limit: 10,
      };
      if (valueSearch) {
        params.search = `documentNumber~${valueSearch}`;
      }
      this.loading.docNumber = true;
      warehouseTransferService
        .listWarehouseTransfer(params)
        .then((res) => {
          res.data.forEach((x, idx) => {
            x["key"] = idx;
            this.listDocumentNumber = res;
          });
        })
        .finally(() => (this.loading.docNumber = false));
    },
    getList(params?: RequestQueryParamsModel): void {
      if (!params) {
        params = {
          page: this.page - 1,
          limit: this.limitPage,
          sorts: this.sortBy,
        };
      }

      if (this.search) {
        params.search = this.search;
      } else {
        delete params.search;
      }
      this.loading.loadingTable = true;
      warehouseTransferService
        .listWarehouseTransfer(params)
        .then((res) => {
          res.data.forEach((x, idx) => {
            x["key"] = idx;
            x["sourceName"] = x.sourceLocation || "-";
            x["destinationName"] = x.destinationLocation || "-";
            x.date = this.moment(x.date).format(DEFAULT_DATE_FORMAT);
          });
          this.dataList = res;
        })
        .finally(() => (this.loading.loadingTable = false));
    },
    responsePageSizeChange(response: ResponsePagination): void {
      this.limitPage = response.size;
      this.page = 1;
      this.getList();
    },
    responseCurrentPageChange(response: ResponsePagination): void {
      this.page = response.page;
      this.getList();
    },
    getListLocation(valueSearch: string) {
      let params: RequestQueryParamsModel = {
        page: 0,
        limit: 10,
      };

      if (valueSearch) {
        params.search = `warehouse.branchWarehouse.name~*${valueSearch}*_OR_warehouse.branchWarehouse.code~*${valueSearch}*_OR_warehouse.branchWarehouse.address~*${valueSearch}*_OR_warehouse.name~*${valueSearch}*_OR_name~*${valueSearch}*`;
      }
      this.loading.source = true;
      this.loading.destination = true;
      warehouseLocationService
        .listWarehouseLocation(params)
        .then((data) => {
          this.dataListLocation = data.data;
        })
        .finally(() => {
          this.loading.destination = false;
          this.loading.source = false;
        });
    },
    filterOption(input, option) {
      return (
        option.componentOptions.children[0].componentOptions.children[1].text
          .toLowerCase()
          .indexOf(input.toLowerCase()) >= 0
      );
    },
    findData(): void {
      this.filterList = this.form.model as any;
      const searchDocNumber = `documentNumber~${this.filterList.docNumber}`;
      const searchState = `state~${this.filterList.status}`;
      const searchSource = `source.secureId~${this.filterList.source}`;
      const searchDestination = `destination.secureId~${this.filterList.destination}`;
      const timeFrom = this.filterList.date?.length
        ? this.moment(this.filterList.date[0])
            .set({ hour: 0, minute: 0, second: 0 })
            .utcOffset("+07")
            .format()
        : "";
      const timeTo = this.filterList.date?.length
        ? this.moment(this.filterList.date[1])
            .set({ hour: 23, minute: 59, second: 59 })
            .utcOffset("+07")
            .format()
        : "";
      const searchDate = `date>=${timeFrom}_AND_date<=${timeTo}`;
      const params: RequestQueryParamsModel = {
        page: 0,
        limit: 10,
        sorts: this.sortBy,
      };
      if (this.filterList.status) {
        params.search = params.search
          ? params.search.concat("_AND_", searchState)
          : searchState;
      }
      if (this.filterList.date) {
        params.search = params.search
          ? params.search.concat("_AND_", searchDate)
          : searchDate;
      }
      if (this.filterList.source) {
        params.search = params.search
          ? params.search.concat("_AND_", searchSource)
          : searchSource;
      }
      if (this.filterList.destination) {
        params.search = params.search
          ? params.search.concat("_AND_", searchDestination)
          : searchDestination;
      }
      if (this.filterList.docNumber) {
        params.search = params.search
          ? params.search.concat("_AND_", searchDocNumber)
          : searchDocNumber;
      }
      this.search = params.search || "";
      this.getList(params);
    },
    clearFilter(): void {
      for (const key in this.form.model) {
        if (Object.prototype.hasOwnProperty.call(this.form.model, key)) {
          this.form.model[key] = undefined;
        }
      }
      this.search = "";
      this.limitPage = 10;
      this.dataList.data = [];
      this.findData();
    },
    onRangePickerChange(dates: Array<any>): void {
      if (!dates.length) {
        this.form.model.date = undefined;
      }
    },
  },
});
